<template>
  <div class="vue-tempalte">
    <b-navbar toggleable="lg" type="light" variant="light" fixed="top">
      <b-navbar-brand
        to="/"
        style="min-width: 150px; text-align: left; color: orange;"
        >WINT</b-navbar-brand
      >
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <!-- Lang -->
        <b-nav-item>
          <template v-for="(val, lang) in supportedLanguagesMapping">
            <button
              :key="lang"
              v-if="!isCurrentLanguage(lang)"
              v-on:click="btnChangeLang"
              :value="lang"
              class="LangBtn"
              style="border: none; background: none; color: rgba(0, 0, 0, 0.5);"
            >
              {{ val }}
            </button>
          </template>
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar>
    <!-- TOTP Dialog -->
    <div class="login-form" v-if="totpDialog">
      <div>
        <p style="color: #9a9af9; cursor: pointer;" @click="returnToLoginInfo">
          {{ $t('login.return') }}
        </p>
      </div>
      <div style="text-align: center;">
        <img
          src="@/assets/smartphone_locked-512.png"
          style="max-width: 100px;"
        />
      </div>
      <div style="margin-top: 30px;">
        <h3>{{ $t('login.enterVerificationCode') }}</h3>
      </div>
      <div>
        {{ $t('login.verificationCodeDescription') }}
      </div>
      <div>
        <input
          v-model="totp"
          type="number"
          class="form-control form-control-lg"
          ref="totp"
          @keyup="loginWithTOTP"
        />
      </div>
      <div>
        <p style="color: red;">{{ errorMsg }}</p>
      </div>
    </div>
    <!-- End of TOTP Dialog -->

    <form class="login-form" v-if="!totpDialog">
      <!-- <h3>{{ $t('login.title') }}</h3> -->
      <h2 class="font-bold text-40px">Hello!</h2>

      <p>{{ $t('login.welcomeMsg') }}</p>
      <div class="form-group mt-8">
        <label>{{ $t('login.email') }}</label>
        <input
          v-model="email"
          type="email"
          class="form-control form-control-lg"
          ref="email"
        />
      </div>

      <div class="form-group">
        <label>{{ $t('login.password') }}</label>
        <input
          v-model="password"
          type="password"
          class="form-control form-control-lg"
        />
      </div>

      <button @click.prevent="Login" class="btn btn-dark btn-lg btn-block">
        {{ $t('login.submit') }}
      </button>

      <!-- <p class="forgot-password text-right mt-2 mb-4">
        <router-link to="/forgot-password">Forgot password ?</router-link>
      </p>-->
      <div>
        <p style="color: red;">{{ errorMsg }}</p>
      </div>
    </form>
  </div>
</template>

<script>
import Vuetify from 'vuetify'
import { Trans } from '@/plugins/Translation'
import axios from 'axios'
import { BASE_API_PATH } from '@/utils/constant'
import VueCookies from 'vue-cookies'
import moment from 'moment'

export default {
  vuetify: new Vuetify(),
  data: function() {
    return {
      records: {},
      email: '',
      password: '',
      errorMsg: '',
      totp: '',
      totpDialog: false
    }
  },
  computed: {
    supportedLanguages() {
      return Trans.supportedLanguages
    },
    currentLanguage() {
      return Trans.currentLanguage
    },
    supportedLanguagesMapping() {
      return Trans.supportedLanguagesMapping
    }
  },
  mounted() {
    // Focus input box
    this.$refs.email.focus()
  },
  methods: {
    isCurrentLanguage(lang) {
      return lang === this.currentLanguage
    },
    btnChangeLang(e) {
      const lang = e.target.value
      const to = this.$router.resolve({ params: { lang } })
      return Trans.changeLanguage(lang).then(() => {
        this.$router.push(to.location)
      })
    },
    async Login() {
      const a = 10
      if (a > 10) return
      const password = this.password
      const email = this.email
      this.errorMsg = ''
      if (password.length < 5 || email.length < 5) {
        this.errorMsg = 'Invalid Email or Password.'
        return
      }
      const { data } = await axios.post(BASE_API_PATH + '/?t=login', {
        email: this.email,
        password: this.password
      })

      const statusCode = data.statusCode
      if (statusCode == 200) {
        this.totp = ''
        this.totpDialog = true
        this.$nextTick(() => {
          this.$refs.totp.focus()
        })
      } else {
        this.errorMsg = data.message
      }
    },
    // Login with TOTP
    async loginWithTOTP() {
      if (this.totp.length == 6) {
        this.errorMsg = ''
        const { data } = await axios.post(BASE_API_PATH + '/?t=login', {
          email: this.email,
          password: this.password,
          totp: this.totp
        })

        this.totp = ''
        const statusCode = data.statusCode
        const token = data.token || ''
        if (statusCode == 200 && token.length > 30) {
          this.writeIndexedDB(data)
        } else {
          this.errorMsg = data.message
        }
      }
    },
    writeIndexedDB(data) {
      const token = data.token
      const tokenExpiry = data.tokenExp
      // LocalStorge
      localStorage.setItem('wintWebsiteToken', token)
      localStorage.setItem(
        'wintWebsiteTokenExp',
        moment().add(tokenExpiry, 's')
      )
      // Vue Cookie
      VueCookies.set('wintWebsiteToken', token)
      VueCookies.set('wintWebsiteTokenExp', moment().add(tokenExpiry, 's'))
      window.location = './'
    },
    returnToLoginInfo() {
      this.email = ''
      this.password = ''
      this.totp = ''
      this.totpDialog = false
    }
  }
}
</script>

<style lang="postcss" scoped>
* {
  box-sizing: border-box;
}

body {
  background: #2554ff !important;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
}

body,
html,
.App,
.vue-tempalte,
.vertical-center {
  width: 100%;
  height: 100%;
  padding: 50px;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.vertical-center {
  display: flex;
  text-align: left;
  justify-content: center;
  flex-direction: column;
}

.inner-block {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.vertical-center .form-control:focus {
  border-color: #2554ff;
  box-shadow: none;
}

.vertical-center h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

label {
  font-weight: 500;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7a7a7a;
  margin: 0;
}

.forgot-password a {
  color: #2554ff;
}

.social-icons {
  text-align: center;
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 1.5em;
  color: #222222;
}

.social-icons ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.social-icons ul li {
  display: inline-block;
  zoom: 1;
  width: 65px;
  vertical-align: middle;
  border: 1px solid #e3e8f9;
  font-size: 15px;
  height: 40px;
  line-height: 40px;
  margin-right: 5px;
  background: #f4f6ff;
}

.social-icons ul li a {
  display: block;
  font-size: 1.4em;
  margin: 0 5px;
  text-decoration: none;
}
.social-icons ul li a i {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.social-icons ul li a:focus i,
.social-icons ul li a:active i {
  transition: none;
  color: #222222;
}

.login-form {
  @apply p-10 bg-white shadow-2xl shadow-orange-900 rounded-xl max-w-600px text-left;
  margin: 0 auto;
  margin-top: 50px;
}
</style>
